'use client'

import { Box, Typography } from '@mui/material'

import { useTranslations } from 'next-intl'

import { ImageSrc } from 'enums/images'

const EmailSent = (): React.JSX.Element => {
  const t = useTranslations()

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      rowGap={2}>
      <Box component="img" height={50} src={ImageSrc.IC_EMAIL_SENT} alt="password" loading="lazy" />

      <Typography variant="h4">{t('checkYourEmail')}</Typography>

      <Typography variant="body2" align="center">
        {t('weSentYouInstructions')}
      </Typography>
    </Box>
  )
}

export default EmailSent
